import { Controller } from '@hotwired/stimulus'
import formbricks from '@formbricks/js'

export default class extends Controller {
  static values = {
    createdAt: Number,
    createdMonth: Number,
    createdYear: Number,
    currentSignIn: Number,
    email: String,
    environment: String,
    everSupporter: Boolean,
    supporter: Boolean,
    userId: String
  }

  initialize () {
    formbricks.init({
      environmentId: this.environmentValue === 'production' ? 'cm0n19o6v0008dn2zfex8jyzb' : 'cm0n19o6d0004dn2z86us9g2w',
      apiHost: 'https://forms.citystrides.com',
      userId: this.userIdValue,
      attributes: {
        createdAt: this.createdAtValue,
        createdMonth: this.createdMonthValue,
        createdYear: this.createdYearValue,
        currentSignIn: this.currentSignInValue,
        everSupporter: this.everSupporterValue,
        supporter: this.supporterValue
      }
    })

    formbricks.setEmail(this.emailValue)
    this.setAttribute('createdAt', this.createdAtValue)
    this.setAttribute('createdMonth', this.createdMonthValue)
    this.setAttribute('createdYear', this.createdYearValue)
    this.setAttribute('currentSignIn', this.currentSignInValue)
    this.setAttribute('everSupporter', this.everSupporterValue)
    this.setAttribute('supporter', this.supporterValue)
    document.addEventListener('turbo:load', this.registerRouteChange())
  }

  disconnect () {
    document.removeEventListener('turbo:load', this.registerRouteChange())
  }

  registerRouteChange () {
    formbricks.registerRouteChange()
  }

  sendAction (actionKey) {
    formbricks.track(actionKey)
  }

  setAttribute (attributeName, attributeValue) {
    formbricks.setAttribute(attributeName, attributeValue)
  }
}
